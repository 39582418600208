<template>
  <El-Form-item :label="label" prop="email" :rules="rules">
    <El-Input
      v-model="email"
      placeholder="請輸入會員Email"
      :disabled="disabled"
    >
      <template #suffix>
        <div class="h-full">
          <font-awesome-icon
            v-if="isValid"
            class="text-primary"
            :icon="['fas', 'check']"
          />
        </div>
      </template>
    </El-Input>
    <div>
      <slot name="suffix" :valid="isValid" :email="email"></slot>
    </div>
  </El-Form-item>
</template>
<script>
import { ref, toRefs } from 'vue';
import { ElFormItem, ElInput, ElMessage } from 'element-plus';
import Regex from '@/utils/Regex';
import User from '@/api/User.js';
import useComputed from '@/hook/useComputed';
export default {
  components: {
    ElFormItem,
    ElInput,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: null,
    },
    modelValue: {
      type: String,
      default: '',
    },
    check: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const { modelValue, check } = toRefs(props);
    const email = useComputed(modelValue, emit);

    const isValid = ref(false);

    const rules = [
      {
        validator: async (rule, value, callback) => {
          isValid.value = false;
          if (!value) {
            callback(new Error('未填寫內容!'));
            return;
          } else if (!Regex.email.test(value)) {
            callback(new Error('請輸入正確的email格式!'));
            return;
          }
          if (check.value) {
            const res = await User.checkEmail({ email: value });
            if (res.data?.errorCode) {
              if (res.data.errorCode === '001') {
                callback(new Error('Email已經被使用!'));
              } else if (res.data.errorCode === '002') {
                callback(
                  new Error('Email已連結至Google帳戶，請使用Google登入。'),
                );
                ElMessage({
                  message: 'Email已連結至Google帳戶，請使用Google登入。',
                  type: 'error',
                });
              }

              return;
            }
          }
          isValid.value = true;
          callback();
        },
        trigger: 'blur',
      },
    ];

    return { isValid, rules, email };
  },
};
</script>
