<template>
  <div v-loading="loading" class="text-center">
    <h2 class="text-5xl text-primary">現在就加入JODY</h2>
    <!-- <a href="#" @click.prevent="deleteAccount">Delete</a> -->
    <El-Form ref="formRef" :model="form" :rules="rules" class="mt-6">
      <Email v-model="form.email" check />
      <Password v-model="form.password" :strong="true" />
      <Phone v-model="form.phone" :disabled="countdown > 0" check>
        <template #suffix="slot">
          <el-button
            size="small"
            round
            :disabled="!slot.valid || countdown > 0"
            type="primary"
            @click="requireCode({ phone: slot.phone })"
            >寄送驗證碼
            <span v-if="countdown > 0">剩下{{ countdown }}</span>
          </el-button>
        </template>
      </Phone>
      <El-Form-item
        prop="code"
        :rules="{
          required: true,
          message: '未填寫內容',
        }"
      >
        <div class>
          <El-Input v-model="form.code" placeholder="請輸入驗證碼"></El-Input>
        </div>
      </El-Form-item>
      <El-Form-item>
        <el-button
          :disabled="!form.code"
          type="primary"
          round
          class="w-full"
          @click="onSubmit"
          >註冊</el-button
        >
      </El-Form-item>
      <p class="my-2">
        註冊即是同意
        <router-link class="underline" :to="'/term'">使用者條款</router-link>
        <router-link class="underline ml-2" :to="'/privacy'"
          >隱私權條款</router-link
        >
      </p>
    </El-Form>

    <hr />
    <div>
      <p class="my-4">或使用以下方式成為會員：</p>
      <Google class="w-full"></Google>
      <p class="mt-4 underline cursor-pointer" @click="toggleLogin">
        已經是會員了嗎？ 按這裡登入
      </p>
    </div>
  </div>
</template>

<script>
import { ref, defineComponent, inject, onMounted } from 'vue';
import { ElFormItem, ElForm, ElInput } from 'element-plus';
import Phone from '@/components/form/Phone.vue';
import Email from '@/components/form/Email.vue';
import Password from '@/components/form/Password.vue';
import Google from './google/Btn.vue';
import { useRouter, useRoute } from 'vue-router';
// import  from './useAuth';
import useRegister from './useRegister';
import useChecker from '@/modules/auth/useChecker';
import User from '@/api/User';

export default defineComponent({
  components: {
    ElFormItem,
    ElForm,
    ElInput,
    Phone,
    Email,
    Password,
    Google,
  },
  setup() {
    const { commit } = inject('store');
    const formRef = ref(null);
    const router = useRouter();
    const route = useRoute();
    const auth = inject('auth');
    const { submit: requireCode, countdown } = useChecker(User.phoneCode);
    const { form, submit, loading, rules } = useRegister(auth);

    const syncDemoUserForm = () => {
      const { email, phone, name } = route.query;

      if (email || phone || name) {
        form.email = email ? email.replace(' ', '+') : '';
        form.phone = phone ? phone : '';
        form.name = name ? name.replace(' ', '+') : '';
        formRef.value.validate();
      }
    };

    const deleteAccount = () => {
      User.delete({ email: 'chieh.lee@gmail.com' });
      User.delete({ phone: '0938525966' });
    };

    const onSubmit = () => {
      formRef.value.validate((valid) => {
        if (valid) {
          submit().then((res) => {
            if (res.code === 1) {
              router.push({ name: 'Signup.profile' });
            }
          });
        } else {
          return false;
        }
      });
    };

    const toggleLogin = () => {
      commit('state', {
        status: 'isLogining',
      });
    };
    onMounted(() => {
      syncDemoUserForm();
    });

    return {
      loading,
      onSubmit,
      form,
      rules,
      formRef,
      toggleLogin,
      requireCode,
      countdown,
      deleteAccount,
    };
  },
});
</script>
