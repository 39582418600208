import User from '@/api/User';
import { ElMessage } from 'element-plus';

import { ref, reactive, inject } from 'vue';

export default () => {
  const auth = inject('auth');
  const form = reactive({
    // email: process.env.NODE_ENV === 'production' ? '' : 'chieh.lee@gmail.com',
    // password: process.env.NODE_ENV === 'production' ? '' : 'Aa0110test',
    // phone: process.env.NODE_ENV === 'production' ? '' : '0938525966',
    // code: process.env.NODE_ENV === 'production' ? '' : '1234',

    email: '',
    password: '',
    phone: '',
    code: '',
  });
  const loading = ref(false);
  const rules = {
    // code: [],
  };
  const submit = async () => {
    loading.value = true;
    const res = await User.signup(form);

    if (res.code !== 1) {
      const errors = {
        '001': 'EMAIL',
        '002': '手機號碼',
        '003': '驗證號碼',
      };
      ElMessage({ message: errors[res.errorCode] + '錯誤' || '發現錯誤' });
      loading.value = false;
    } else {
      auth.setAuth(res.data);
      ElMessage({ message: '成功註冊', type: 'success' });
      const resStatus = await auth.status();
      if (res.code === 1) {
        auth.setAuth(resStatus.data);
      }
    }

    loading.value = false;
    return Promise.resolve(res);
  };

  return {
    loading,
    form,
    submit,
    rules,
  };
};
